.carousel__dot {
  width: 50px;
  margin-right: 6px;
  border-radius: 100px;
  background-color: #e5cce7;
  border: 2px solid #e5cce7;
}

.carousel__dot--selected {
  background-color: #7e0386;
  border: 2px solid #7e0386;
}
.slick-dots li
{ 
    width: 14px!important;
}