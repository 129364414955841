.recharts-legend-wrapper {
  width: 100% !important;
  left: 0 !important;
  line-height: 23px;
}

.recharts-legend-item {
  margin-right: 12px !important;
}

.recharts-legend-item-text {
  color: #969696 !important;
}
